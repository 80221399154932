* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --primaryColor: #af9a7d;
  --mainWhite: #fff;
  --mainLavender: #a27a45;
  --mainBlack: #222;
  --lightBlack: rgb(133, 135, 135);
  --mainGrey: #ececec;
  --darkGrey: #cfcfcf;
  --mainRed: #fa0000;
  --mainTransition: all 0.3s linear;
  --mainSpacing: 3px;
  --lightShadow: 2px 3px 3px 0px rgba(0, 0, 0, 0.5);
  --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
}
/* globals */
body {
  padding-top: 66px;
  color: var(--mainBlack);
  background: var(--mainWhite);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  line-height: 1.4;
}
h1 {
  font-size: 3em;
  line-height: 1;
  margin-bottom: 0.5em;
}
h2 {
  font-size: 2em;
}
h3 {
  font-size: 1.5em;
  line-height: 1;
  margin-bottom: 1em;
}
h4 {
  font-size: 1.2em;
  line-height: 1.25;
  margin-bottom: 1.25em;
}
h5 {
  font-size: 0.9em;
  margin-bottom: 0;
}
h6 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}

.font-size-1 {
  font-size: 0.8em !important;
}

.btn-primary {
  display: inline-block;
  text-decoration: none;
  letter-spacing: var(--mainSpacing);
  color: var(--mainBlack);
  background: var(--primaryColor);
  padding: 0.4rem 0.9rem;
  border: 3px solid var(--primaryColor);
  transition: var(--mainTransition);
  text-transform: uppercase;
  cursor: pointer;
}
.btn-primary:hover {
  background: transparent;
  color: var(--primaryColor);
}
.btn-danger {
  background-color: var(--mainRed);
}
.loading {
  text-transform: capitalize;
  text-align: center;
  margin-top: 3rem;
}
.error {
  text-align: center;
  text-transform: uppercase;
  margin: 2rem 0;
}
.empty-search {
  text-align: center;
  text-transform: capitalize;
  margin: 2rem 0;
  padding: 1rem;
  letter-spacing: var(--mainSpacing);
}
/* end of globals */
/* Navbar */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.5rem 2rem;
  background: var(--mainLavender);
  z-index: 1;
  flex-direction: column;
}
.navbar-search {
	width: 60%;
	border: 1px solid var(--mainGrey);
	padding: 2px 0 2px 10px;
	background-color: var(--mainWhite);
	border-radius: 8px;
  display: flex;
  height: 24px;
  margin-top: 2px !important;

	input {
		border: 0;
		width: 100%;
    outline: none;
	}
  input::placeholder {
    font-size: 0.8 em;
  }
	button {
		opacity: 0.5;
    padding: 0 10px;
	}
}

@media screen and (max-width: 725px) {
  #root {
    font-size: 80%;
  }
  .navbar-search {
    width: 100%;
  }
}

.nav-header {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .nav-btn {
    display: none;
  }
  .nav-center {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
  }
  .nav-links {
    height: auto;
    display: flex;
    margin-left: 4rem;
  }
  .nav-links a {
    margin: 0 1rem;
    padding: 0.1rem 0;
  }
}

.nav-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}
.nav-icon {
  font-size: 1.5rem;
  color: var(--primaryColor);
}
.nav-links {
  overflow: hidden;
  transition: var(--mainTransition);
  list-style-type: none;
}

@media screen and (max-width: 1065px) {
  .nav-links {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 5px;

    li {
      display: inline-block;
      border-radius: 0 !important;
      margin: 0 5px 0 5px;

      a {
        padding: 0;
      }
    }
    img {
      width: 40px;
      height: 30px;
      object-fit: cover;
      max-width: 100%;
      display: block;
    }
    h5, .btn-book {
      position: absolute;
      left: -9999px;
    }
  }
}
.nav-links a {
  display: block;
  text-decoration: none;
  padding: 1rem 0;
  color: var(--mainBlack);
  transition: var(--mainTransition);
  text-align: center;
  font-size: 0.8rem;
  font-weight: 600;
  letter-spacing: var(--mainSpacing);
}
.nav-links a:hover {
  color: var(--primaryColor);
}

@media screen and (min-width: 768px) {
  .nav-btn {
    display: none;
  }
  .nav-center {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
  }
  .nav-links {
    height: auto;
    display: flex;
    margin-left: 4rem;
  }
  .nav-links a {
    margin: 0 0.5rem;
    padding: 0.5rem 0;
  }
}
/* end of navbar */
/* Hero */
.defaultHero,
.roomsHero {
  min-height: calc(100vh - 66px);
  background: url("../assets/img/jpeg/defaultBcg2.jpg") center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.roomsHero {
  background-image: url("../assets/img/jpeg/bg-staffs.png");
  min-height: 30vh;
}
/* End of Hero */
/* Banner */
.banner {
  display: inline-block;
  background: rgba(0, 0, 0, 0.5);
  color: var(--mainWhite);
  padding: 2rem 1rem;
  text-align: center;
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}
.banner h1 {
  font-size: 2rem;
}
.banner div {
  width: 10rem;
  height: 5px;
  background: var(--primaryColor);
  margin: 1.7rem auto;
}
.banner p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
@media screen and (min-width: 576px) {
  .banner {
    padding: 2rem 3rem;
  }
  .banner h1 {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 992px) {
  .banner {
    padding: 1rem 6rem;
  }
  .banner h1 {
    font-size: 3rem;
  }
}
/* End of Banner */
/* Title */
.section-title {
  text-align: center;
  margin-bottom: 2rem;
}
.section-title h4 {
  font-size: 2rem;
  letter-spacing: var(--mainSpacing);
  text-transform: capitalize;
  margin-bottom: 1rem;
}
.section-title div {
  width: 5rem;
  height: 5px;
  margin: 0 auto;
  background: var(--primaryColor);
}
/* end of Title */

/* services */
.services {
  padding: 1rem 0;
}
.services {
  background: var(--darkGrey);
  text-align: center;
}
.services-center {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
  grid-row-gap: 1rem;
  grid-column-gap: 50px;
}
.service span {
  display: inline-block;
  color: var(--primaryColor);
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}
.services h6 {
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}
.services p {
  width: 80%;
  margin: 0 auto;
  color: var(--mainBlack);
}
@media screen and (min-width: 992px) {
  .services-center {
    width: 95vw;
    max-width: 1170px;
  }
}

@media screen and (min-width: 1200px) {
  .services p {
    width: 100%;
  }
}
/*end of services */
/* featured rooms */

.featured-rooms {
  padding: 5rem 0;
}
.featured-rooms-center {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 40px;
}
@media screen and (min-width: 776px) {
  .featured-rooms-center {
    width: 90vw;
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  }
}
@media screen and (min-width: 992px) {
  .featured-rooms-center {
    width: 95vw;
    max-width: 1170px;
  }
}
/* end pf featured rooms */
/* room */
.room {
  box-shadow: var(--lightShadow);
  transition: var(--mainTransition);
}
.room:hover {
  box-shadow: var(--darkShadow);
}

.img-container {
  position: relative;
}
.img-container img {
  width: 100%;
  display: block;
  transition: var(--mainTransition);
}
.img-price-list {
  width: 40%;
}

@media screen and (max-width: 725px) {
  .img-price-list {
    width: 80%;
  }
  .img-container {
    height: 150px;
  }
  .logo {
    width: 40%;
  }
}
.price-top {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  color: var(--mainWhite);
  padding: 0.3rem 0.6rem 0.5rem;
  border-bottom-right-radius: 1rem;
  font-size: 0.5rem;
  text-align: center;
  transition: var(--mainTransition);
}
.price-top h6 {
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: 300;
  letter-spacing: var(--mainSpacing);
}
.room-link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(0);
  transition: all 0.3s linear;
}
.img-container:hover {
  background: rgba(0, 0, 0, 0.8);
}
.img-container:hover img {
  opacity: 0.3;
}
.img-container:hover .price-top {
  opacity: 0;
}
.img-container:hover .room-link {
  transform: translate(-50%, -50%) scale(1);
}
.staff-info {
  background: #000c;
  text-transform: capitalize;
  text-align: center;
  font-weight: 700;
  letter-spacing: var(--mainSpacing);
  margin: 0;
}
.price-info {
  text-align: center;
  font-weight: 700;
  letter-spacing: var(--mainSpacing);
  color: var(--mainLavender);
}
/* end of room  */
/* single room*/

.single-room {
  padding: 2rem 0 0 0;
}
.single-room-images {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 50px;
}
.single-room-images img {
  width: 100%;
  display: block;
}
.single-room-info {
  width: 80vw;
  display: grid;
  grid-template-columns: 1fr;
  margin: auto;
}
.desc,
.info {
  margin: 1rem 0;
}
.desc h3 {
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}
.desc p {
  color: #222222;
  line-height: 1.5;
}
.info h3,
.info h6 {
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}

.info h6 {
  font-weight: 300;
}
.room-extras {
  width: 80vw;
  margin: 2rem auto 2rem auto;
}
.room-extras h6 {
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}
.extras {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
}
@media screen and (min-width: 992px) {
  .single-room-images,
  .single-room-info,
  .room-extras {
    width: 95vw;
    max-width: 1170px;
  }
  .single-room-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
  }
}
/* end of single room*/
/* roomList */
.roomslist {
  padding: 5rem 0;
}
.roomslist-center {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 30px;
}

@media screen and (min-width: 776px) {
  .roomslist-center {
    width: 90vw;
  }
}
@media screen and (min-width: 992px) {
  .roomslist-center {
    width: 95vw;
    max-width: 1170px;
  }
}
/* end of roomList */
/* rooms filter */
.filter-container {
  padding: 1rem 0;
}

.filter-container .form-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.filter-container .form-group .form-control {
  margin: 0.5rem 0;
}

/* Mobile styles */
@media (max-width: 768px) {
  .filter-container .form-group .form-control {
    width: 100%;
  }
}

.filter-form {
  width: 60vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(202px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 40px;
}
.form-group {
  text-transform: capitalize;
}
.form-group label {
  display: block;
  letter-spacing: var(--mainSpacing);
}
.form-control {
  width: 100%;
  background: transparent;
  font-size: 1rem;
}
.size-inputs {
  display: flex;
}
.size-input {
  width: 40%;
  padding: 0.2rem;
  border: 1px solid var(--mainBlack);
  border-radius: 0.3rem;
  margin-right: 0.3rem;
}
.single-extra label {
  display: inline-block;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}
@media screen and (min-width: 776px) {
  .filter-form {
    width: 70vw;
  }
}
@media screen and (min-width: 992px) {
  .filter-form {
    width: 95vw;
    max-width: 1170px;
  }
}
/* end of rooms filter */

/* footer style */
.footer {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #222;
}
.footer h2,
p {
  color: white;
}
.footer a {
  color: white;
  text-decoration: none;
}
.footer a:hover {
  color: red;
  text-decoration: underline;
  transition: var(--mainTransition);
  -webkit-transition: var(--mainTransition);
  -moz-transition: var(--mainTransition);
  -ms-transition: var(--mainTransition);
  -o-transition: var(--mainTransition);
}

.staff {
  border: 1px solid #ddd;
  margin: 10px;
  text-align: center;
  width: 200px; /* Ensure each card has the same width */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  position: relative; /* Needed for button positioning */
}

.img-container {
  position: relative;
  overflow: hidden;
}

.img-container:hover {
  transition: var(--mainTransition);
  -webkit-transition: var(--mainTransition);
  -moz-transition: var(--mainTransition);
  -ms-transition: var(--mainTransition);
  -o-transition: var(--mainTransition);
}

.fixed-size-img {
  width: 100%;
  height: 250px; /* Adjust this value as needed */
  object-fit: cover; /* Ensures the image covers the area without distortion */
}

.staff-details {
  padding: 10px;
}

.staff-id {
  font-size: 1rem;
  font-weight: bold;
  margin: 10px 0;
}

.staff-price {
  color: red;
  font-weight: bold;
}

.btn-primary.staff-link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  text-decoration: none;
  display: none; /* Hide the button initially */
}

.staff:hover .btn-primary.staff-link {
  display: block; /* Show the button on hover */
}

.staffslist {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin: auto;
}

.staffslist-center {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 725px) {
  .staffslist-center {
    justify-content: center;
  }
}
