.staff-card {
  font-family: Arial, sans-serif;
  padding: 10px;
  max-width: 90%;
  margin: 0 auto;
  border: 0.1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-header {
  max-width: 90%;
}

.breadcrumb {
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 20px;
  margin-left: 50px;
}

.breadcrumb a {
  color: #008cba;
  text-decoration: none;
}

.breadcrumb span {
  color: #666;
}

h2 {
  font-size: 24px;
  text-align: center;
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (min-width: 726px) {
  .card-content {
    flex-direction: row; /* Change to row for larger screens */
    /* justify-content: space-between; */
  }
}

.profile-pic {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.top-details, .work-time, .pricing {
  margin: 10px 0;
}

.service-options, .time-options, .space-options {
  margin: 10px 0;
}

button {
  margin: 5px;
  padding: 5px 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.8em;
}

button.active {
  background-color: #02a4da;
  color: white;
}

button.highlight {
  background-color: #f0c040;
  color: white;
}

.select-button {
  background-color: var(--mainRed);
  color: white;
  margin-top: 10px;
  width: 100%;
  padding: 10px;
}

.image-gallery {
  width: 100%;
  max-width: 400px; /* Adjust this value as needed for smaller width */
  margin-top: 20px;
}

.image-gallery img {
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: cover;
}

.carousel .thumb.selected, .carousel .thumb:hover {
  border: 1px solid #333333;
}

@media (min-width: 726px) {
  .image-gallery {
    width: 50%; /* Make image gallery take up half the width on larger screens */
  }

  .details {
    width: 45%; /* Adjust as needed to balance with image gallery */
    margin-left: 20px;
    text-align: left;
  }

  .image-gallery .carousel .thumbs-wrapper {
    margin: 0;
  }

  .image-gallery .carousel .thumbs {
    display: flex;
    justify-content: center;
  }

  .image-gallery .carousel .thumb {
    width: 60px;
    height: 60px;
    margin: 5px;
    border-radius: 4px;
    overflow: hidden;
  }
}
