@import "App.css";

.checkout-container {
  display: block;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  color: #333;
}

.checkout-container h1 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2.5em;
  color: #444;
}

.checkout-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

input[type="text"]
{
  font-size: 0.8em;
}

.shipping-info, .order-summary {
  flex: 1 1 48%;
  max-width: 48%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #fff;
}

.shipping-info h2, .order-summary h2 {
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #555;
}

.shipping-info input, .shipping-info textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.input-error {
  border: 1px solid red !important;
}

.error {
  color: red;
  margin-bottom: 15px;
  margin-top: 0px;
  text-transform: lowercase;
  text-align: left;
  font-size: 0.8rem;
}

.error::first-letter {
  text-transform: uppercase;
}

.error-note {
  padding: 1rem;
  background-color: #FFC0CB;
  color: rgb(253, 47, 47);
  border-radius: 5px;
}

.note {
  padding: 15px;
  background: #f9f9f9;
  border-radius: 5px;
}

.note h2 {
  margin-bottom: 10px;
  font-size: 1.2em;
}

.note label {
  display: flex;
  align-items: center;
  font-size: 0.9em;
}

.note input {
  margin-right: 10px;
  width: 5%;
}

.note-description {
  font-size: 0.8em;
}

.note-extra {
  font-size: 0.8em !important;
  margin-top: 10px;
}

.product-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.product-item img {
  width: 30%;
  border-radius: 5px;
  margin-right: 15px;
}

.product-item h5 {
  margin: 0 0 5px 0;
  font-size: 1.2em;
}

.product-item p {
  margin: 5px 0;
  font-size: 0.9em;
  color: #777;
}

.total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  margin-top: 20px;
  font-size: 1.2em;
}

.book-now {
  display: block;
  background: var(--mainRed);
  color: #fff;
  padding: 10px;
  text-align: center;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  margin-top: 20px;
  font-size: 1.2em;
  transition: background 0.3s ease;
}

.book-now:hover {
  background: #c9302c;
}

.return-to-cart {
  display: block;
  text-align: center;
  margin-top: 20px;
  text-decoration: none;
  color: #007bff;
}

.return-to-cart:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .shipping-info, .order-summary {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

.PhoneInput {
  margin-bottom: 5px;
}

.PhoneInput input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  margin: 0;
  font-size: 0.8em;
}

.input-error-phone .PhoneInputInput {
  border: 1px solid red;
}

.input-error-phone {
  border: 0;
}